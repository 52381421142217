import React, { useState, useEffect } from 'react'
import { db } from "../../../Config/Config";
import { collection, query, where, getDocs, getDoc, doc, Timestamp } from 'firebase/firestore';
import { DataGrid } from "@mui/x-data-grid";
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Sidebar from '../Sidebar/Sidebar';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Loader from '../../Loader/Loader';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import axios from 'axios';


export default function PendingTrans() {
    const [trans, setTrans] = useState([]);
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
    const [isLoading, setIsLoading] = useState(false);

    // Function to check transfer status from Razorpay
    const checkTransfer = async (paymentId) => {
        try {
            const res = await axios.get(`https://us-central1-deliverykartiitj.cloudfunctions.net/checkTransfer?paymentId=${paymentId}`);
            const { noTransfers } = res.data;
            return noTransfers;
        } catch (error) {
            toast.error('Error checking transfer:', error, { containerId: 'pendingTrans' });
            return false;
        }
    };

    // Function to check refund status from Razorpay
    const checkRefund = async (paymentId) => {
        try {
            const res = await axios.get(`https://us-central1-deliverykartiitj.cloudfunctions.net/checkRefundStatus?paymentId=${paymentId}`);
            const { noRefunds } = res.data;
            return noRefunds;
        } catch (error) {
            toast.error('Error checking refund:', error, { containerId: 'pendingTrans' });
            return false;
        }
    };


    const pendingTransCol = [
        {
            field: 'paymentId',
            headerName: 'Payment ID',
            width: 200,
            renderCell: (params) => {
                const paymentId = params.value;
                return <div>{paymentId}</div>;
            },
        },

        {
            field: 'userName',
            headerName: 'User Name',
            width: 250,
            renderCell: (params) => {
                const userName = params.value;
                return <div>{userName}</div>;
            }
        },

        {
            field: 'shopName',
            headerName: 'Shop Name',
            width: 250,
            renderCell: (params) => {
                const shopName = params.value;
                return <div>{shopName}</div>;
            }
        },

        {
            field: 'totalAmount',
            headerName: 'Total Amount',
            width: 250,
            renderCell: (params) => {
                const totalAmount = params.value;
                return <div>{totalAmount}</div>;
            }
        },

        {
            field: 'itemSubtotal',
            headerName: 'Shop Amount',
            width: 250,
            renderCell: (params) => {
                const totalAmount = params.row.totalAmount;
                const deliveryFee = params.row.deliveryCharge || 0;
                const charge = params.row.convenienceCharge || 0;

                const shopAmount = totalAmount - deliveryFee - charge;

                return <div>{shopAmount.toFixed(2)}</div>;
            }
        },

        {
            field: 'routeId',
            headerName: 'Route Id',
            width: 250,
            renderCell: (params) => {
                const routeId = params.value;
                return <div>{routeId}</div>;
            }
        },

        {
            field: 'status',
            headerName: 'Order Status',
            width: 250,
            renderCell: (params) => {
                const status = params.value;
                return <div>{status}</div>;
            }
        },

        {
            field: 'timestamp',
            headerName: 'Timestamp',
            width: 200,
            renderCell: (params) => {
                const timestamp = params.value?.toDate();
                const options = {
                    year: 'numeric',
                    month: 'short',
                    day: 'numeric',
                    hour: '2-digit',
                    minute: '2-digit',
                    second: '2-digit',
                    hour12: true
                };
                const formattedTimestamp = new Intl.DateTimeFormat('en-US', options).format(timestamp);
                return <div>{formattedTimestamp}</div>;
            }
        },
        {
            field: 'updatedAt',
            headerName: 'Updated Status',
            width: 200,
            renderCell: (params) => {
                if (params.value) {
                    const timestamp = params.value?.toDate();
                    const options = {
                        year: 'numeric',
                        month: 'short',
                        day: 'numeric',
                        hour: '2-digit',
                        minute: '2-digit',
                        second: '2-digit',
                        hour12: true
                    };
                    const formattedTimestamp = new Intl.DateTimeFormat('en-US', options).format(timestamp);
                    return <div>{formattedTimestamp}</div>;
                }
                return <div>No Update</div>;
            }
        },
    ];

    const findRouteId = async (shopId) => {
        try {
            // Use doc() instead of collection() for a specific document
            const docRef = doc(db, 'businessUsers', shopId);
            const docSnap = await getDoc(docRef);

            if (docSnap.exists()) {
                console.log(docSnap.data().routeId);
                return docSnap.data().routeId;
            } else {
                console.log('No routeId found for shop:', shopId);
                return null;
            }
        } catch (error) {
            toast.error('Error fetching routeId:', error, { containerId: 'pendingTrans' });
            return null;
        }
    };

    // Fetch orders and filter them based on conditions
    const handleSearchBtn = async () => {
        if (!startDate || !endDate) {
            toast.error('Please select a date range before searching!', { containerId: "pendingTrans" });
            return;
        }

        const formattedStartDate = Timestamp.fromDate(new Date(startDate));
        const formattedEndDate = Timestamp.fromDate(new Date(endDate));

        try {
            setIsLoading(true);
            const q = query(
                collection(db, 'Orders'),
                where('updatedAt', '>=', formattedStartDate),
                where('updatedAt', '<=', formattedEndDate)
            );
            const querySnapshot = await getDocs(q);
            const fetchedOrders = [];

            for (const doc of querySnapshot.docs) {
                const order = doc.data();
                const paymentId = order.paymentId;

                // Condition 1: Check for Delivered orders without a transferId
                if (order.status === 'Delivered' && !order.transferId) {
                    const isTransferNotCreated = await checkTransfer(paymentId);
                    if (isTransferNotCreated) {
                        const routeId = await findRouteId(order.shopId);
                        fetchedOrders.push({ ...order, routeId });
                    }
                }

                // Condition 2: Check for Cancelled orders without a refundId
                else if (order.status === 'Cancelled' && order.refundId) {
                    const isRefundNotCreated = await checkRefund(paymentId);
                    if (isRefundNotCreated) {
                        const routeId = await findRouteId(order.shopId);
                        fetchedOrders.push({ ...order, routeId });
                    }
                }
            }

            if (fetchedOrders.length === 0) {
                toast.info('No payments left to be created!', { containerId: "pendingTrans" });
                setIsLoading(false);
                return
            }

            setTrans(fetchedOrders);
        } catch (error) {
            toast.error('Error fetching PENDING TRANS:', error, { containerId: 'pendingTrans' });
        } finally {
            setIsLoading(false);
        }
    };

    const handleCreateTrans = async () => {
        try {
            setIsLoading(true);
            for (const order of trans) {
                const { paymentId, totalAmount, deliveryCharge, convenienceCharge, routeId, status } = order;

                if (status === 'Delivered') {
                    const amt = (totalAmount - (deliveryCharge ?? 0) - convenienceCharge) * 100;
                    console.log('Creating transfer for:', paymentId, amt, deliveryCharge, convenienceCharge, routeId);

                    // Send data as query parameters for routePayment function
                    const res = await axios.post(
                        `https://us-central1-deliverykartiitj.cloudfunctions.net/routePayment`,
                        null, // No body, since data is passed via query params
                        {
                            params: {
                                payment_id: paymentId,
                                amt: amt,
                                account_id: routeId
                            }
                        }
                    );
                    console.log('Transfer Response:', res.data);

                } else if (status === 'Cancelled') {
                    console.log('Creating refund for:', paymentId, totalAmount, deliveryCharge, convenienceCharge, routeId);

                    // Send data as query parameters for refundPayment function
                    const res = await axios.post(
                        `https://us-central1-deliverykartiitj.cloudfunctions.net/refundPayment`,
                        null, // No body, since data is passed via query params
                        {
                            params: {
                                payment_id: paymentId,
                                amt: totalAmount * 100 // Refund amount in paise (INR)
                            }
                        }
                    );
                    console.log('Refund Response:', res.data);
                }
            }
        } catch (error) {
            console.error('Error creating transfer:', error);
            toast.error('Error creating transfer:', { containerId: 'pendingTrans' });
        } finally {
            setIsLoading(false);
        }
    };


    return (
        <>
            <div className="home">
                <Sidebar />
                <div className="homeContainer">
                    <div className="listContainer">
                        <div className="datatable">
                            <ToastContainer
                                position="top-right"
                                containerId="pendingTrans"
                                autoClose={2400}
                                hideProgressBar={false}
                                newestOnTop={false}
                                closeOnClick
                                rtl={false}
                                pauseOnFocusLoss
                                draggable
                                pauseOnHover
                                theme="light" />
                            {isLoading && <Loader />}
                            <div className="datatableTitle">
                                Pending Transactions
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <div style={{ display: 'flex', gap: '10px' }}>
                                        <DatePicker
                                            label="From"
                                            value={startDate}
                                            onChange={(newValue) => setStartDate(newValue)}
                                            renderInput={(params) => <TextField {...params} />}
                                        />
                                        <DatePicker
                                            label="To"
                                            value={endDate}
                                            onChange={(newValue) => setEndDate(newValue)}
                                            renderInput={(params) => <TextField {...params} />}
                                        />
                                    </div>
                                </LocalizationProvider>
                                <Button variant="contained" onClick={handleSearchBtn}>Search</Button>
                            </div>

                            <div style={{ height: '100%', width: '100%' }}>
                                {/* Check if there are transactions */}
                                {trans.length === 0 ? (
                                    <div style={{ textAlign: 'center', padding: '20px', fontSize: '18px' }}>
                                        No payments left to be created
                                    </div>
                                ) : (
                                    <DataGrid
                                        rows={trans}
                                        columns={pendingTransCol}
                                        getRowId={(row) => row.paymentId}
                                        initialState={{
                                            pagination: {
                                                paginationModel: { pageSize: 10, page: 0 },
                                            },
                                        }}
                                        pageSizeOptions={[10, 25, 50]}
                                        checkboxSelection
                                        disableSelectionOnClick
                                        getRowHeight={() => 'auto'}
                                        autosizeOptions={{
                                            columns: ['id', 'products', 'userAddress', 'userPhone'],
                                            includeOutliers: true,
                                            includeHeaders: false,
                                        }}
                                    />
                                )}
                            </div>

                            <div className="datatableTitle" style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                <Button variant="contained" style={{ marginTop: "10px" }} onClick={handleCreateTrans}>Create Trans</Button>
                            </div>
                        </div>
                    </div>
                </div >
            </div >
        </>
    )
}
