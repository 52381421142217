import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import { useContext } from "react";
import "./assets/App.css";
import "bootstrap-icons/font/bootstrap-icons.css";
import NavMenu from "./components/NavMenu";
import LandingPage from "./components/LandingPage/LandingPage";
import Register from "./components/RegLogin/Register";
import Login from "./components/RegLogin/Login";
import ScrollTop from "./components/ScrollTop";
import FooterComp from "./components/Footer/FooterComp";
import FaqBuyer from "./components/FAQ/FaqBuyer";
import FaqSeller from "./components/FAQ/FaqSeller";
import ContactUs from "./components/Contact/ContactUs";
import Account from "./components/Users/Account";
import Error from "./components/Error";
import Product from "./components/Shops/Product";
import AddNewProduct from "./components/Shops/AddNewProduct";
import ModifyProduct from "./components/Shops/ModifyProduct";
import Admin from "./components/Admin/Admin";
import Home from "./components/Shops/Home";
import Profile from "./components/Shops/Profile";
import Food from "./components/FoodMenu/Food";
import { AuthContext } from "./components/Context/AuthContext";
import MyCart from "./components/Order/MyCart";
import OrderHistory from "./components/Order/OrderHistory";
import Orders from "./components/Shops/Orders";
import ContactAdmin from "./components/Shops/ContactAdmin";
import AdminLogin from "./components/Admin/AdminLogin";
import BusinessUsersList from "./components/Admin/BusinessUsersList";
import BusinessUserPrd from "./components/Admin/BusinessUserPrd";
import OrderDetails from "./components/Admin/OrderDetails";
import UserQueryList from "./components/Admin/QueryList";
import { userQueryColumns } from "./components/Admin/userQuerySource";
import { businessQueryColumns } from "./components/Admin/businessQuerySource";
import UserQuery from "./components/Admin/UserQuery";
import BusinessQuery from "./components/Admin/BusinessQuery";
import PastOrder from "./components/Shops/PastOrder";
import PasswordReset from "./components/RegLogin/PasswordReset";
import IntroDetailPage from "./components/Users/IntroDetailPage";
import ShopList from "./components/Services/ShopList";
import PrivacyPolicy from "./components/PolicyPages/PrivacyPolicy";
import TermsConditions from "./components/PolicyPages/TermsConditions";
import CancellationRefund from "./components/PolicyPages/CancellationRefund";
import DeliveryPolicy from "./components/PolicyPages/DeliveryPolicy";
import DeleteAccount from "./components/DeleteAccount";
import ManageUsers from "./components/Shops/ManageUsers";
import AddNewUser from "./components/Shops/AddNewUser";
import OwnerRefund from "./components/Shops/OwnerRefund";
import PendingTrans from "./components/Admin/PendingTrans/PendingTrans";

function AdminRoute({ element }) {
  const { currentUser } = useContext(AuthContext);

  if (currentUser && currentUser.userType === 'Admin') {
    return element;
  } else {
    return <Navigate to="/error" />;
  }
}

function App() {
  const { currentUser } = useContext(AuthContext);

  function requireUserType(userType, allowedTypes, component) {
    if (allowedTypes.includes(userType)) {
      return component;
    } else {
      return <Navigate to="/error" />;
    }
  }

  return (
    <>
      <Router>
        <ScrollTop />
        {(!currentUser || currentUser.userType === "user") && !window.location.pathname.includes("/introDetails") && <NavMenu />}
        <Routes>
          <Route exact path="/login" element={<Login />} />
          <Route exact path="/passwordReset" element={<PasswordReset />} />
          <Route exact path="/" element={<LandingPage />} />
          <Route exact path="/service" element={<ShopList />} />
          <Route exact path="/adminLogin" element={<AdminLogin />} />
          <Route exact path="/admin" element={<AdminRoute element={<Admin />} />} />
          <Route exact path="/admin/businessUsers" element={<AdminRoute element={<BusinessUsersList />} />} />
          <Route exact path="/admin/orderDetails" element={<AdminRoute element={<OrderDetails />} />} />
          <Route exact path="/admin/pendingTransaction" element={<AdminRoute element={<PendingTrans />} />} />
          <Route exact path="/admin/userQuery" element={<AdminRoute element={<UserQueryList userDB={'QueryUsers'} userDbSource={userQueryColumns} />} />} />
          <Route exact path="/admin/userQuery/:queryUserId" element={<AdminRoute element={<UserQuery />} />} />
          <Route exact path="/admin/businessQuery" element={<AdminRoute element={<UserQueryList userDB={'QueryBusinessUsers'} userDbSource={businessQueryColumns} />} />} />
          <Route exact path="/admin/businessQuery/:queryUserId" element={<AdminRoute element={<BusinessQuery />} />} />
          <Route exact path="/admin/businessUsers/:businessUserId" element={<AdminRoute element={<BusinessUserPrd />} />} />
          <Route exact path="/register" element={<Register />} />
          <Route exact path="/faqBuyer" element={<FaqBuyer />} />
          <Route exact path="/faqSeller" element={<FaqSeller />} />
          <Route exact path="/deleteAccount" element={<DeleteAccount />} />
          <Route exact path="/cancellation&Refund" element={<CancellationRefund />} />
          <Route exact path="/deliveryPolicy" element={<DeliveryPolicy />} />
          <Route exact path="/terms&Conditions" element={<TermsConditions />} />
          <Route exact path="/privacyPolicy" element={<PrivacyPolicy />} />
          <Route exact path="/contact" element={<ContactUs />} />
          <Route exact path="/account" element={requireUserType(currentUser && currentUser.userType, ["user"], <Account />)} />
          <Route exact path="/introDetails" element={requireUserType(currentUser && currentUser.userType, ["user"], <IntroDetailPage />)} />
          <Route exact path="/cart" element={requireUserType(currentUser && currentUser.userType, ["user"], <MyCart />)} />
          <Route exact path="/order" element={requireUserType(currentUser && currentUser.userType, ["user"], <OrderHistory />)} />
          <Route exact path="/error" element={<Error />} />
          <Route exact path="/business" element={requireUserType(currentUser && currentUser.userType, ["businessUser"], <Home />)} />
          <Route exact path="/business/product" element={requireUserType(currentUser && currentUser.userType, ["businessUser"], <Product />)} />
          <Route exact path="/business/order" element={requireUserType(currentUser && currentUser.userType, ["businessUser"], <Orders />)} />
          <Route exact path="/business/transaction" element={requireUserType(currentUser && currentUser.userType, ["businessUser"], <PastOrder />)} />
          <Route exact path="/business/profile" element={requireUserType(currentUser && currentUser.userType, ["businessUser"], <Profile />)} />
          <Route exact path="/business/contactAdmin" element={requireUserType(currentUser && currentUser.userType, ["businessUser"], <ContactAdmin />)} />
          <Route exact path="/business/product/add" element={requireUserType(currentUser && currentUser.userType, ["businessUser"], <AddNewProduct />)} />
          <Route exact path="/business/manageUsers" element={requireUserType(currentUser && currentUser.userType, ["businessUser"], <ManageUsers />)} />
          <Route exact path="/business/adminRefund" element={requireUserType(currentUser && currentUser.userType, ["businessUser"], <OwnerRefund />)} />
          <Route exact path="/business/product/modify/:id" element={requireUserType(currentUser && currentUser.userType, ["businessUser"], <ModifyProduct />)} />
          <Route exact path="/business/addNewUser" element={requireUserType(currentUser && currentUser.userType, ["businessUser"], <AddNewUser />)} />
          <Route exact path="/:shopId" element={<Food />} />
        </Routes>
        {(!currentUser || currentUser.userType === "user") && !window.location.pathname.includes("/introDetails") && <FooterComp />}
      </Router>
    </>
  );
}

export default App;
