import React, { useState, useEffect } from 'react';
import "./admin.css";
import Sidebar from './Sidebar/Sidebar';
import Chart from './Chart/Chart';
import Widget from './Widget/Widget';
import Featured from './Featured/Featured';
import { collection, query, where, getDocs } from 'firebase/firestore';
import { auth, db } from '../../Config/Config';
import Loader from '../Loader/Loader';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import VerifyBusinessUsers from './VerifyBusinessUsers';

export default function Admin() {
    const [totalTodayRevenue, setTotalTodayRevenue] = useState(0);
    const [totalLastDayRevenue, setTotalLastDayRevenue] = useState(0);
    const [totalLastWeekRevenue, setTotalLastWeekRevenue] = useState(0);
    const [totalLastMonthRevenue, setTotalLastMonthRevenue] = useState(0);
    const [todayCancelledOrders, setTodayCancelledOrders] = useState(0);
    const [todayDeliveredOrders, setTodayDeliveredOrders] = useState(0);
    const [categoryOrderData, setCategoryOrderData] = useState([]);
    const [businessUsersNumber, setBusinessUsersNumber] = useState(0);
    const [businessUsersQueryNumber, setBusinessUsersQueryNumber] = useState(0);
    const [usersNumber, setUsersNumber] = useState(0);
    const [usersQueryNumber, setUsersQueryNumber] = useState(0);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const fetchNumbers = async () => {
            setIsLoading(true);

            const businessUsersCollectionRef = collection(db, 'businessUsers');
            const businessUsersQueryCollectionRef = collection(db, 'QueryBusinessUsers')
            const usersCollectionRef = collection(db, 'users');
            const usersQueryCollectionRef = collection(db, 'QueryUsers')

            try {
                const queryBusinessUsersSnapshot = await getDocs(businessUsersCollectionRef);

                const queryBusinessLeft = query(businessUsersQueryCollectionRef, where('status', 'in', ['In Progress', '']));
                const queryBusinessUsersQuerySnapshot = await getDocs(queryBusinessLeft);

                const queryUsersSnapshot = await getDocs(usersCollectionRef);

                const queryUserLeft = query(usersQueryCollectionRef, where('status', 'in', ['In Progress', '']));
                const queryUsersQuerySnapshot = await getDocs(queryUserLeft);

                setBusinessUsersNumber(queryBusinessUsersSnapshot.size);
                setBusinessUsersQueryNumber(queryBusinessUsersQuerySnapshot.size);
                setUsersNumber(queryUsersSnapshot.size);
                setUsersQueryNumber(queryUsersQuerySnapshot.size);
            } catch (error) {
                toast.error('Error Fetching Numbers. Please Try After SomeTime!!');
            } finally {
                setIsLoading(false);
            }
        };
        const unsubscribeAuthStateChange = auth.onAuthStateChanged((user) => {
            if (user) {
                fetchNumbers();
                //             const fetchOrdersDetails = async () => {
                //                 setIsLoading(true);
                //                 try {
                //                     const ordersCollectionRef = collection(db, 'Orders');
                //                     const querySnapshot = await getDocs(ordersCollectionRef);

                //                     const categoryCounts = {
                //                         'Italian': 0,
                //                         'Rolls & Breads': 0,
                //                         'Indian Main Course': 0,
                //                         'Chinese': 0,
                //                         'Starters': 0,
                //                         'South Indian': 0
                //                     };

                //                     let totalQuantity = 0;

                //                     querySnapshot.forEach((doc) => {
                //                         const ordersData = doc.data();
                //                         ordersData.products.forEach((product) => {
                //                             const category = product.category;
                //                             if (category in categoryCounts) {
                //                                 categoryCounts[category] += product.quantity;
                //                             }
                //                             totalQuantity += product.quantity;
                //                         });
                //                     });

                //                     const categoryData = Object.entries(categoryCounts).map(([category, quantity]) => {
                //                         return {
                //                             category: category,
                //                             percentage: ((quantity / totalQuantity) * 100).toFixed(2)
                //                         };
                //                     });

                //                     setCategoryOrderData(categoryData);

                //                     // Calculate today's revenue
                //                     const today = new Date();
                //                     const todayStart = new Date(today.getFullYear(), today.getMonth(), today.getDate());
                //                     const todayEnd = new Date(todayStart);

                //                     todayEnd.setDate(todayStart.getDate() + 1);

                //                     const todayCancelledQuery = query(
                //                         ordersCollectionRef,
                //                         where('status', '==', 'Cancelled'),
                //                         where('updatedAt', '>=', todayStart),
                //                         where('updatedAt', '<', todayEnd)
                //                     );

                //                     const todayCancelledOrdersSnapshot = await getDocs(todayCancelledQuery);
                //                     let todayCancelledOrdersCount = 0;
                //                     todayCancelledOrdersSnapshot.forEach((doc) => {
                //                         todayCancelledOrdersCount++;
                //                     });
                //                     setTodayCancelledOrders(todayCancelledOrdersCount);

                // const todayOrdersQuery = query(
                //     ordersCollectionRef,
                //     where('status', '==', 'Delivered'),
                //     where('updatedAt', '>=', todayStart),
                //     where('updatedAt', '<', todayEnd)
                // );

                // const todayOrdersSnapshot = await getDocs(todayOrdersQuery);
                // let todayRevenue = 0;
                // let todayDeliveredOrdersCount = 0;
                // todayOrdersSnapshot.forEach((doc) => {
                //     const orderData = doc.data();
                //     todayRevenue += parseFloat(orderData.totalAmount);
                //     todayDeliveredOrdersCount++;
                // });
                // setTotalTodayRevenue(todayRevenue);
                // setTodayDeliveredOrders(todayDeliveredOrdersCount);

                //                     // Calculate last day's revenue
                //                     const lastDayStart = new Date(todayStart);
                //                     lastDayStart.setDate(todayStart.getDate() - 1);

                //                     const lastDayOrdersQuery = query(
                //                         ordersCollectionRef,
                //                         where('status', '==', 'Delivered'),
                //                         where('updatedAt', '>=', lastDayStart),
                //                         where('updatedAt', '<', todayStart)
                //                     );
                //                     const lastDayOrdersSnapshot = await getDocs(lastDayOrdersQuery);
                //                     let lastDayRevenue = 0;
                //                     lastDayOrdersSnapshot.forEach((doc) => {
                //                         const orderData = doc.data();
                //                         lastDayRevenue += parseFloat(orderData.totalAmount);
                //                     });
                //                     setTotalLastDayRevenue(lastDayRevenue);


                //                     // Calculate last week's revenue
                //                     const lastWeekStart = new Date(todayStart);
                //                     lastWeekStart.setDate(todayStart.getDate() - 7);

                //                     const lastWeekOrdersQuery = query(
                //                         ordersCollectionRef,
                //                         where('status', '==', 'Delivered'),
                //                         where('updatedAt', '>=', lastWeekStart),
                //                         where('updatedAt', '<', todayStart)
                //                     );
                //                     const lastWeekOrdersSnapshot = await getDocs(lastWeekOrdersQuery);
                //                     let lastWeekRevenue = 0;
                //                     lastWeekOrdersSnapshot.forEach((doc) => {
                //                         const orderData = doc.data();
                //                         lastWeekRevenue += parseFloat(orderData.totalAmount);;
                //                     });
                //                     setTotalLastWeekRevenue(lastWeekRevenue);

                //                     // Calculate last month's revenue
                //                     const lastMonthStart = new Date(todayStart);
                //                     lastMonthStart.setMonth(todayStart.getMonth() - 1);

                //                     const lastMonthOrdersQuery = query(
                //                         ordersCollectionRef,
                //                         where('status', '==', 'Delivered'),
                //                         where('updatedAt', '>=', lastMonthStart),
                //                         where('updatedAt', '<', todayStart)
                //                     );
                //                     const lastMonthOrdersSnapshot = await getDocs(lastMonthOrdersQuery);
                //                     let lastMonthRevenue = 0;
                //                     lastMonthOrdersSnapshot.forEach((doc) => {
                //                         const orderData = doc.data();
                //                         lastMonthRevenue += parseFloat(orderData.totalAmount);;
                //                     });
                //                     setTotalLastMonthRevenue(lastMonthRevenue);
                //                 } catch (error) {
                //                     toast.error('Error fetching orders numbers. Please Try After Sometime!!');
                //                 } finally {
                //                     setIsLoading(false);
                //                 }
                //             };
                //             fetchOrdersDetails();
                //         } else {
                //             setIsLoading(false);
            }
        });

        return () => {
            unsubscribeAuthStateChange();
        };
    }, []);


    return (
        <>
            <div className="home">
                <Sidebar />
                <ToastContainer
                    position="top-right"
                    autoClose={2400}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    theme="light" />
                {isLoading && <Loader />}
                <div className="homeContainer">
                    <div className="widgets">
                        <Widget type="user" amount={usersNumber} isMoney={false} toLink='/admin' />
                        <Widget type="businessUser" amount={businessUsersNumber} isMoney={false} toLink='/admin/businessUsers' />
                        <Widget type="earning" amount={totalTodayRevenue} isMoney={true} toLink='/admin' />
                        <Widget type="perDayOrders" amount={todayDeliveredOrders} isMoney={false} toLink='/admin/orderDetails' />
                        <Widget type="userQuery" amount={usersQueryNumber} isMoney={false} toLink='/admin/userQuery' />
                        <Widget type="businessQuery" amount={businessUsersQueryNumber} isMoney={false} toLink='/admin/businessQuery' />
                    </div>
                    {/* <div className="charts">
                        <Featured totalTodayRevenue={totalTodayRevenue} totalLastDayRevenue={totalLastDayRevenue} totalLastWeekRevenue={totalLastWeekRevenue} totalLastMonthRevenue={totalLastMonthRevenue} todayCancelledOrders={todayCancelledOrders} todayDeliveredOrders={todayDeliveredOrders} />
                        <Chart title="Category-Wise Percentage Sales" aspect={2 / 1} categoryOrderData={categoryOrderData} />
                    </div> */}
                    <div className="listContainer">
                        <div className="listTitle">Verify New Businesses</div>
                        <VerifyBusinessUsers />
                    </div>
                </div>
            </div>
        </>
    )
}
